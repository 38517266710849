<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <div class="ad">
        <!--广告容器-->
        <div class="ad_container">
          <!--分栏广告-->
          <ColumnAds></ColumnAds>
          <!--文字广告-->
          <TextAds class="mt22"></TextAds>
        </div>
      </div>
      <div class="content">
        <!--经典球员-->
        <div class="title_column">
          <div class="title">经典球员 CLASSIC PLAYER</div>
          <a href="/sportsman">更多</a>
        </div>
        <div class="title_content">
          <div class="item" v-for="(item, index) in sportsman_list" :key="index">
            <img :src="item.cover_info.image" alt="球员图片"  @click="jump_page('sportsman_detail', {Id: item._id}, true)">
            <div class="text">
              <span class="name" @click="jump_page('sportsman_detail', {Id: item._id}, true)">{{ item.cover_info.chinese_name }}</span>
              <p class="name" @click="jump_page('sportsman_detail', {Id: item._id}, true)">{{ item.cover_info.english_name }}</p>
              <p>出生日期：{{ item.cover_info.date_of_birth }}</p>
              <p>场上位置：{{ item.cover_info.field_position }}</p>
              <p>巅峰能力：{{ item.cover_info.peak_ability }}</p>
              <p>代表国家：{{ item.cover_info.represent_the_country }}</p>
            </div>
          </div>
        </div>
        <!--球队阵容-->
        <div class="title_column">
          <div class="title">球队阵容 CLUB LIST</div>
          <a href="#">更多</a>
        </div>
        <div class="title_content">
          <div class="item2" v-for="(item, index) in team_list" :key="index">
            <img :src="item.cover_info.cover_image" alt="球员图片" style="width: 125px; height: 125px;">
            <div class="describe">
              <p class="title" @click="jump_page('team_detail', {Id: item._id}, true)">{{ item.cover_info.cover_title }}</p>
<!--              <p class="version">适用版本：FM2022 3.0</p>-->
              <p>{{ item.cover_info.cover_describe }}</p>
            </div>
          </div>
        </div>
        <!--广告-->
        <div class="title_content">
          <BannerAds class="mg013 mb10"></BannerAds>
        </div>
        <!--最佳阵容-->
        <div class="title_column">
          <div class="title">最佳阵容 DREAM TEAM</div>
          <a href="#">更多</a>
        </div>
        <div class="title_content">
          <div class="item2" v-for="(item, index) in optimal_list" :key="index">
            <img :src="item.cover_info.cover_image" alt="球员图片" style="width: 125px; height: 125px;">
            <div class="describe">
              <p class="title" @click="jump_page('optimal_detail', {Id: item._id}, true)">{{ item.cover_info.cover_title }}</p>
              <!--              <p class="version">适用版本：FM2022 3.0</p>-->
              <p>{{ item.cover_info.cover_describe }}</p>
            </div>
          </div>
        </div>
        <!--数据下载-->
        <div class="title_column">
          <div class="title">数据下载 DATA DOWNLOAD</div>
          <a href="#">更多</a>
        </div>
        <div class="title_content">
          <div class="item2" v-for="(item, index) in data_list" :key="index">
            <img :src="item.cover_info.cover_image" alt="球员图片">
            <div class="describe">
              <p class="title" @click="jump_page('data_detail', {Id: item._id}, true)">{{ item.cover_info.cover_title }}</p>
              <!--              <p class="version">适用版本：FM2022 3.0</p>-->
              <p>{{ item.cover_info.cover_describe }}</p>
            </div>
          </div>
        </div>
        <!--皮肤下载-->
        <div class="title_column">
          <div class="title">皮肤下载 SKIN DOWNLOAD</div>
          <a href="#">更多</a>
        </div>
        <div class="title_content">
          <div class="item2" v-for="(item, index) in skin_list" :key="index">
            <img :src="item.cover_info.cover_image" alt="球员图片">
            <div class="describe">
              <p class="title" @click="jump_page('skin_detail', {Id: item._id}, true)">{{ item.cover_info.cover_title }}</p>
              <!--              <p class="version">适用版本：FM2022 3.0</p>-->
              <p>{{ item.cover_info.cover_describe }}</p>
            </div>
          </div>
        </div>
        <!--战术下载-->
        <div class="title_column">
          <div class="title">战术下载 TACTICS DOWNLOAD</div>
          <a href="#">更多</a>
        </div>
        <div class="title_content">
          <div class="item2" v-for="(item, index) in tactics_list" :key="index">
            <img :src="item.cover_info.cover_image" alt="球员图片">
            <div class="describe">
              <p class="title" @click="jump_page('tactics_detail', {Id: item._id}, true)">{{ item.cover_info.cover_title }}</p>
              <!--              <p class="version">适用版本：FM2022 3.0</p>-->
              <p>{{ item.cover_info.cover_describe }}</p>
            </div>
          </div>
        </div>
        <!--视频下载-->
        <div class="title_column">
          <div class="title">视频下载 VIDEO DOWNLOAD</div>
          <a href="#">更多</a>
        </div>
        <div class="title_content">
          <div class="item2" v-for="(item, index) in video_list" :key="index">
            <img :src="item.cover_info.cover_image" alt="球员图片">
            <div class="describe">
              <p class="title" @click="jump_page('video_detail', {Id: item._id}, true)">{{ item.cover_info.cover_title }}</p>
              <!--              <p class="version">适用版本：FM2022 3.0</p>-->
              <p>{{ item.cover_info.cover_describe }}</p>
            </div>
          </div>
        </div>
        <!--视频下载-->
        <div class="title_column">
          <div class="title">文章 ARTICLE</div>
          <a href="#">更多</a>
        </div>
        <div class="title_content">
          <div class="item2" v-for="(item, index) in article_list" :key="index">
            <img :src="item.cover_info.cover_image" alt="球员图片">
            <div class="describe">
              <p class="title" @click="jump_page('article_detail', {Id: item._id}, true)">{{ item.cover_info.cover_title }}</p>
              <!--              <p class="version">适用版本：FM2022 3.0</p>-->
              <p>{{ item.cover_info.cover_describe }}</p>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import ColumnAds from "../components/ColumnAds";
import TextAds from "../components/TextAds";
import BannerAds from "../components/BannerAds";
import {get_home_data_sportsman, get_home_data_article} from "../api/api";
import {jump_page} from "../tool/tools";
import API_CONFIG from "../../vue.config";

export default {
  name: "Home",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;")
  },
  mounted() {
    this.obtain_sportsman_data();
    this.obtain_team_data();
    this.obtain_optimal_data();
    this.obtain_data_data();
    this.obtain_skin_data();
    this.obtain_tactics_data();
    this.obtain_video_data();
    this.obtain_article_ata();
  },
  data(){
    return {
      sportsman_list: [],
      team_list: [],
      optimal_list: [],
      data_list: [],
      skin_list: [],
      tactics_list: [],
      video_list: [],
      article_list: []
    }
  },
  methods: {
    jump_page,
    // 获取球员数据
    obtain_sportsman_data(){
      get_home_data_sportsman().then(response => {
        if(response.Code === 1){
          this.sportsman_list = response.Data;
          for(let i=0; i <= this.sportsman_list.length; i++){
            this.sportsman_list[i]["cover_info"]["image"] = API_CONFIG.devServer.proxy["/api"].target + "/" + this.sportsman_list[i]["cover_info"]["image"];
          }
        }
      });
    },
    // 获取球队数据
    obtain_team_data(){
      get_home_data_article({"type": 0}).then(response => {
        if(response.Code === 1){
          this.team_list = response.Data;
          for(let i=0; i <= this.team_list.length; i++){
            this.team_list[i].cover_info.cover_image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.team_list[i].cover_info.cover_image;
          }
        }
      });
    },
    // 获取最佳数据
    obtain_optimal_data(){
      get_home_data_article({"type": 1}).then(response => {
        if(response.Code === 1){
          this.optimal_list = response.Data;
          for(let i=0; i <= this.optimal_list.length; i++){
            this.optimal_list[i].cover_info.cover_image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.optimal_list[i].cover_info.cover_image;
          }
        }
      });
    },
    // 获取数据数据
    obtain_data_data(){
      get_home_data_article({"type": 2}).then(response => {
        if(response.Code === 1){
          this.data_list = response.Data;
          for(let i=0; i <= this.data_list.length; i++){
            this.data_list[i].cover_info.cover_image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.data_list[i].cover_info.cover_image;
          }
        }
      });
    },
    // 获取皮肤数据
    obtain_skin_data(){
      get_home_data_article({"type": 3}).then(response => {
        if(response.Code === 1){
          this.skin_list = response.Data;
          for(let i=0; i <= this.skin_list.length; i++){
            this.skin_list[i].cover_info.cover_image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.skin_list[i].cover_info.cover_image;
          }
        }
      });
    },
    // 获取战术数据
    obtain_tactics_data(){
      get_home_data_article({"type": 4}).then(response => {
        if(response.Code === 1){
          this.tactics_list = response.Data;
          for(let i=0; i <= this.tactics_list.length; i++){
            this.tactics_list[i].cover_info.cover_image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.tactics_list[i].cover_info.cover_image;
          }
        }
      });
    },
    // 获取视频数据
    obtain_video_data(){
      get_home_data_article({"type": 5}).then(response => {
        if(response.Code === 1){
          this.video_list = response.Data;
          for(let i=0; i <= this.video_list.length; i++){
            this.video_list[i].cover_info.cover_image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.video_list[i].cover_info.cover_image;
          }
        }
      });
    },
    // 获取文章数据
    obtain_article_ata(){
      get_home_data_article({"type": 6}).then(response => {
        if(response.Code === 1){
          this.article_list = response.Data;
          for(let i=0; i <= this.article_list.length; i++){
            console.log(API_CONFIG.devServer.proxy["/api"].target + "/" + this.article_list[i].cover_info.cover_image);
            this.article_list[i].cover_info.cover_image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.article_list[i].cover_info.cover_image;
          }
        }
      });
    }
  },
  components: {
    Header,
    Footer,
    ColumnAds,
    TextAds,
    BannerAds
  }
}
</script>

<style scoped>
@import "../static/universal/index.css";

.content > .title_column{
  height: 36px;
  background: #2e2f31;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.content > .title_column > .title {
  font-size: 15px;
}

.content > .title_column > a{
  font-size: 12px;
  color: #FFFFFF;
  text-decoration: none;
}

.content > .title_column > a:hover{
  text-decoration: underline;
}

.content > .title_content {
  padding: 0 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.content > .title_content > .item {
  width: 304px;
  background: #f7f8fa;
  margin: 0px 12px 10px 12px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.content > .title_content > .item > img{
  width: 100px;
  height: 100px;
  margin-right: 13px;
}

.content > .title_content > .item > .describe {
  font-size: 12px;
  flex: 1;
  width: 200px;
}

.content > .title_content > .item > .describe > .name{
  color: #35b260;
  font-weight: bold;
  cursor: pointer;
}

.content > .title_content > .item > .describe > .name:hover{
  color: #cc2a33;
}

.content > .title_content > .item > .describe > p{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 17px;
}

.content > .title_content > .item2 {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 12px 10px 12px;
  background: #f7f8fa;
}

.content > .title_content > .item2 > img{
  width: 230px;
  height: 120px;
  cursor: pointer;
}

.content > .title_content > .item2 > .describe{
  padding: 13px;
  flex: 1;
  line-height: 17px;
  font-size: 12px;
}

.content > .title_content > .item2 > .describe > p{
  line-height: 17px;
}

.content > .title_content > .item2 > .describe > .title{
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  color: #35b260;
  margin-bottom: 10px;
  cursor: pointer;
}

.content > .title_content > .item2 > .describe > .title:hover{
  color: #cc2a33;
}

.content > .title_content > .item2 > .describe > .version{
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  color: #959595;
  margin-bottom: 10px;
}

.text{
  flex: 1;
  width: 200px;
  font-size: 12px;
}

.text > p{
  width: 100%;
  height: 17px;
  line-height: 17px;
}

.text > .name{
  color: #35b260;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.text > .name:hover{
  color: #a11e2e;
}

</style>