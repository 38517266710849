<template>
    <div class="column_ad">
      <div class="item">
        <img :src="ad" @click="jump_page">
        <el-tag class="ml-2 ad_tag danger" color="#cc2b33">广告</el-tag>
      </div>
      <div class="item">
        <img :src="ad" @click="jump_page">
        <el-tag class="ml-2 ad_tag danger" color="#cc2b33">广告</el-tag>
      </div>
      <div class="item">
        <img :src="ad" @click="jump_page">
        <el-tag class="ml-2 ad_tag danger" color="#cc2b33">广告</el-tag>
      </div>
    </div>
</template>

<script>
export default {
  name: "ColumnAds",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;");

  },
  data(){
    return {
      ad: require("../static/ad/20231217.jpg")
    }
  },
  methods: {
    jump_page(){
      this.$router.push("https://fmdata.top/data_detail?Id=657c4ecb4eb7a7a8fde52646")
    }
  }
}
</script>

<style scoped>
div {
  padding: 0;
  margin: 0;
}

.column_ad{
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.column_ad > .item{
  width: 335px;
  height: 190px;
  position: relative;
}
.column_ad > .item > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.column_ad > .item > .ad_tag{
  position: absolute;
  bottom: 0;
  right: 0;
  color: #FFFFFF;
  border: 0;
}
</style>